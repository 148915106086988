.home__bg {
  background-image: url(../img/home_bg.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bg-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.welcome-overlay {
  width: 60%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.welcome-overlay::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100vh 0 0 50vh;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 100%;
}
.code-snippet span {
  font-size: 30px !important;
}

li.nav__items {
  font-size: 30px;
  padding-bottom: 15px;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-right: 20px;
}
li.nav__items:hover {
  transform: scale(1.2);
  color: rgb(135, 190, 238);
}

.home__content {
  padding-left: 15px;
}

h1.home__text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  color: white !important;
}

h2.home__text {
  font-size: 54px;
  line-height: 54px;
  font-weight: 800;
  font-family: Georgia, "Times New Roman";
}

h3.home__text {
  font-size: 40px;
  font-weight: 800;
  font-family: Georgia, "Times New Roman";
  line-height: 40px;
}

h3 {
  display: inline-block;
}

.container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  position: relative;
  z-index: 5;
}

.suitpic {
  width: 500px;
}

.grad-icon {
  max-width: 50px;
}

.swe-icon {
  max-width: 40px;
}

.red_text {
  color: #e61231;
  padding-left: 10px;
}

.badges_container {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.px_30 {
  margin-top: 60px;
}

.px_20 {
  margin-top: 35px;
}
.px_10 {
  margin-top: 10px;
}
.mx_20 {
  margin-left: 5px;
  margin-top: 25px;
}
.whitefont {
  color: white;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px;
}

.orange {
  /* color: #CF4420; */
  color: rgb(252, 96, 18);
  padding-left: 10px;
}

header {
  position: fixed;
  margin-top: 0;
}

.pixhundred {
  margin-top: 120px;
}

.certification {
  width: 175px;
}

ul.skill__list {
  list-style-type: square;
  list-style-position: inside;
  color: #fff;
  padding: 10px;
}

/*Responsive*/
@media only screen and (max-width: 768px) {
  h2.home__text {
    font-size: 30px !important;
  }
  h3.home__text {
    font-size: 30px !important;
  }
  span {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 768px) {
  .certification {
    width: 120px !important;
  }
}
