@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap");

/* Rest of the CSS styles */

.image-container {
  position: fixed;
  top: 45%;
  right: 10%;
  transform: translate(50%, -50%);
  z-index: -1;
}

.code-snippet-container {
  position: fixed;
  top: 50%;
  right: 90%;
  transform: translate(50%, -50%);
  z-index: -1;
}

.spdlimit-image{
  max-width: 150px;
}

.code-snippet {
  padding: 20px;
  border-radius: 8px;
  font-family: "Fira Code", monospace;
  font-size: 14px !important;
  color: #d4d4d4;
}
.code-snippet span{
  font-size: 20px !important;
}

.keyword {
  color: #dcdcaa;
}

.function {
  color: #ce9178;
}
.function span {
  font-size: 20px !important;
}

.quotes-div {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.ex-quotes-div-bottom{
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.ex-quote {
  color: white !important;
  font-size: 50pt;
  font-family: "Tangerine", cursive;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.experience-container {
  flex-direction: row;
  flex-flow: wrap;
  position: relative;
  z-index: 5;
}

.experience {
  --color: rgb(255, 255, 255);
  --bgColor: rgb(0, 0, 0);
  min-height: 100vh;
  display: grid;
  align-content: center;
  font-family: "Poppins", sans-serif;
  color: var(--color);
}

.experience-header {
  padding-top: 40px;
  padding-bottom: 60px;
}

.experiece_bg {
  background-image: url(../img/roadmap.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.experience-bg-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.company-logo {
  max-width: 100%;
}

.school-logo {
  max-width: 80%;
}

.descr,
.title {
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
}

.experience-ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
.experience-ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.experience-ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.experience-ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.experience-ul li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.experience-ul li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

:root {
  --accent-color: #41516c;
  --bgColor: white; /* Set a specific background color value */
}

/* circle */
.experience-ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.experience-ul li .title,
.experience-ul li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
.experience-ul li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.experience-ul li .descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.experience-ul li .title::before,
.experience-ul li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.experience-ul li .title::before {
  bottom: calc(100% + 0.125rem);
}

.experience-ul li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 40rem) {
  .experience-ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .experience-ul::before {
    grid-column: 2;
  }
  .experience-ul li:nth-child(odd) {
    grid-column: 1;
  }
  .experience-ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .experience-ul li:nth-child(2) {
    grid-row: 2/4;
  }

  .experience-ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .experience-ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .experience-ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}

.credits {
  margin-top: 1rem;
  text-align: right;
}
.credits a {
  color: var(--color);
}
